<template lang="pug">
  .planning-table
    v-card
      .planning-table__header
        .planning-table__header-column {{ 'base.recipe' | translate }}
        .planning-table__header-column {{ 'base.weight' | translate }} #[br] {{ 'base.plan_fact' | translate }}
        .planning-table__header-column {{ 'base.start_time' | translate }}
        .planning-table__header-column {{ 'base.duration' | translate }}
        .planning-table__header-column {{ 'base.total_mixing_time' | translate }}
        .planning-table__header-column {{ 'base.unloaded' | translate }}
        .planning-table__header-column {{ 'base.status' | translate }}

    v-table-body(
      v-if="!loading"
      gap
      outside-table
      :permission="permissions.read_timetable")
      planning-table-row(
        v-for="(item, index) in timetables"
        :key="item.id"
        :item="item"
        :disabled="isDisabled(item)"
        @select="onSelect")

      planning-table-empty(
        v-if="!timetables.length")

    planning-table-row-skeleton(
      v-else
      :count="6")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import permissions from '@/util/permissions'

import PlanningTableRow from './PlanningTableRow2'
import PlanningTableEmpty from './PlanningTableEmpty'
import PlanningTableRowSkeleton from './PlanningTableRowSkeleton'

export default {
  name: 'PlanningTable',

  components: {
    PlanningTableRow,
    PlanningTableEmpty,
    PlanningTableRowSkeleton
  },

  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    permissions: permissions
  }),

  methods: {
    ...mapActions([
      'updateTimetable'
    ]),

    onSelect (item) {
      this.$emit('select', item)
    },

    onChange (item) {
      this.updateTimetable(item)
    },

    isDisabled (item) {
      const date = new Date(item.planned_at)
      const today = new Date()
      const res = today.getDay() - date.getDay()
      return res < 0 ||
        item.download === 1 ||
        item.status === 'in_progress'
    }
  },

  computed: {
    ...mapGetters([
      'timetables'
    ])
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';
  @import '@/sass/components/_info-tooltip.scss';
  @import '@/sass/base/_transition.scss';

  .planning-table {
    font-size: 1.5rem;

    &__lock-overlay {
      position: absolute;
      width: 100%;
      top: 5rem;
      bottom: 0;
      background-color: rgba($color-white, .6);
      z-index: 100;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    &__header {
      height: 5.1rem;
      padding: .8rem 1.5rem;
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 1.5rem;

      &-column {
        color: $color-gray;
        overflow: hidden;

        &:nth-child(1) {
          width: 22%;
        }

        &:nth-child(2) {
          width: 14%;
        }

        &:nth-child(3) {
          width: 11%;
        }

        &:nth-child(4) {
          width: 14%;
        }

        &:nth-child(5) {
          width: 15%;
        }

        &:nth-child(6) {
          width: 15%;
        }

        &:nth-child(7) {
          width: 20%;
        }

        &:last-child {
          padding-right: 64px;
        }
      }
    }
  }
</style>
